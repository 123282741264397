import React, { useContext, useEffect } from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { AnnouncementsProvider } from 'utils/hooks/useAnnouncements/context';
import { UserContext } from 'utils/hooks/useUserContext/context';
import {
  Product,
  MembershipProductType,
  MembershipTypeOptions,
  Announcement,
  HeroSectionType,
  PageSectionType,
} from 'utils/types';
import NOTIFICATIONS from '../../../components/ToastNotification/constants';
import { UserSettingsProvider } from '../../../utils/hooks/useAccountSettings/context';
import { NotificationContext } from '../../Notification';
import MemberViewContainer from '../MemberViewContainer';
import NonMemberViewContainer from '../NonMemberViewContainer';

type Props = {
  membershipProduct: MembershipProductType;
  data: {
    product: Product;
    content: {
      announcement: Announcement;
      heroSection: HeroSectionType;
      sections: PageSectionType[];
      authorizedSections: PageSectionType[];
    };
  };
  location: any;
};
export const CurrentMembershipContainer: React.FunctionComponent<Props> = ({
  membershipProduct,
  data,
  ...props
}) => {
  const { location } = props;
  const { content } = data;
  const { sections, authorizedSections } = content;
  const { setNotificationData } = useContext(NotificationContext);
  const {
    userData: {
      loggedIn,
      isMember,
      userId,
      fetched,
      membershipType,
      membershipState,
    },
  } = useContext(UserContext);

  useEffect(() => {
    if (fetched) {
      ewAnalytics.track({
        data: {
          Page: isMember
            ? 'Current Pilot - Test Credit Page'
            : 'Current Pilot - Enrollment Page',
        },
        event: ANALYTICS.EVENTS.CURRENT_VIEWED_PAGE,
      });
      if (isMember && membershipState === 'failed') {
        setNotificationData(
          NOTIFICATIONS.PAYMENT_FAILED('Current Pilot - Test Credit Page'),
        );
      }
    }
  }, [fetched]);

  useEffect(() => {
    if (location?.state?.notifyCurrentMember) {
      setNotificationData(
        NOTIFICATIONS.ALREADY_A_MEMBER(
          'Membership Hero',
          membershipType ?? MembershipTypeOptions.current,
        ),
      );
      const { state } = location;
      delete state.notifyCurrentMember;
      window.history.replaceState(state, '');
    }
  }, [location?.state]);

  const memberAuthorized = loggedIn && isMember && membershipType === 'sti';

  const children = memberAuthorized ? (
    <MemberViewContainer
      membershipProduct={membershipProduct}
      pageSections={authorizedSections}
      isMember={memberAuthorized}
      membershipType={membershipType ?? ''}
      // @ts-ignore
      usesrId={userId}
      data={data}
      {...props}
    />
  ) : (
    <NonMemberViewContainer
      membershipProduct={membershipProduct}
      pageSections={sections}
      data={data}
      loggedIn={loggedIn}
      isMember={false}
      {...props}
    />
  );
  return (
    <UserSettingsProvider>
      <AnnouncementsProvider>{children}</AnnouncementsProvider>
    </UserSettingsProvider>
  );
};

export default CurrentMembershipContainer;
