import Layout from 'components/Layout';
import { SEO } from 'components/SEO';
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import { getProductsBySlug, loadScrollToAnchor, logError } from 'utils/helpers';
import {
  Announcement,
  FaqSchema,
  HeroSectionType,
  PageSectionType,
  Product,
  Variant,
} from 'utils/types';
import CurrentMembershipContainer from '../containers/Memberships/CurrentMembershipContainer';

type Props = {
  pageContext: {
    product: Product;
    heroSection: HeroSectionType;
    sections: PageSectionType[];
  };

  data: {
    product: Product;
    content: {
      announcement: Announcement;
      heroSection: HeroSectionType;
      sections: PageSectionType[];
      authorizedSections: PageSectionType[];
      faqSchema: FaqSchema;
      title: string;
    };
    allSpreeProduct: any;
  };
  location: any;
};

function getMembershipProduct(variants: Variant[]): Variant | undefined {
  return variants.find((variant) => variant.subscription_plan !== null);
}

function currentMembershipPage(props: Props): React.ReactElement<Props> | null {
  const { data } = props;
  const { allSpreeProduct, product } = data;
  const { nodes } = allSpreeProduct;
  const productsBySlug = getProductsBySlug(nodes);
  const membershipProduct = getMembershipProduct(product.variants);
  const faqSchema = data.content.faqSchema;
  const title = data.content.title;

  if (!membershipProduct) {
    logError(
      'Did not find master Current Membership Product from list of variants on the product',
      {
        product,
      },
    );
    return null;
  }

  useEffect(() => {
    const scrollTimer = setTimeout(() => {
      loadScrollToAnchor();
    }, 1300);

    return () => clearTimeout(scrollTimer);
  }, []);

  return (
    <>
      <SEO faqSchema={faqSchema} title={title} />
      <Layout productsBySlug={productsBySlug}>
        <CurrentMembershipContainer
          membershipProduct={membershipProduct}
          {...props}
        />
      </Layout>
    </>
  );
}

export default currentMembershipPage;

export const data = graphql`
  query {
    content: contentfulPage(pageId: { eq: "Membership PDP - Current" }) {
      title
      announcement {
        heading
        subheading
        slug
        primaryCta {
          text
          url
        }
        secondaryCta {
          text
          url
        }
        body {
          childMarkdownRemark {
            html
          }
        }
        sys {
          revision
        }
        id
      }
      faqSchema {
        answer
        question
      }
      topBanner {
        headline
        mainCta {
          text
          url
        }
      }
      heroSection {
        componentName
        headline
        description
        desktopImage {
          description
          gatsbyImageData(placeholder: BLURRED, width: 1653, quality: 100)
        }
        mobileImage {
          description
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
        mainCta {
          text
          url
        }
        secondaryHeadline
        secondaryCtaList {
          text
          url
        }
        listOfContentWithImages {
          heading
          image {
            description
            gatsbyImageData(placeholder: BLURRED, width: 164, quality: 100)
          }
        }
        backgroundColor
      }
      sections {
        sectionId
        componentName
        componentVariation
        title
        slug
        headline
        subHeadline
        description {
          childMarkdownRemark {
            html
          }
          description
        }
        subHeadlineDescription {
          childMarkdownRemark {
            html
          }
          subHeadlineDescription
        }
        mainCta {
          text
          url
          appearance
        }
        listOfContentWithImages {
          slug
          heading
          subheading
          title
          description {
            childMarkdownRemark {
              html
            }
            description
          }
          image {
            description
            gatsbyImageData(placeholder: BLURRED, width: 414, quality: 100)
          }
          cta {
            text
            url
            appearance
          }
          subheading
          number
          identifier
          featureFlag
        }
        desktopImages {
          description
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
        mobileImages {
          description
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
        listOfLinks {
          text
          url
        }
        backgroundColor
      }
      authorizedSections {
        sectionId
        componentName
        componentVariation
        title
        slug
        headline
        subHeadline
        description {
          childMarkdownRemark {
            html
          }
          description
        }
        subHeadlineDescription {
          childMarkdownRemark {
            html
          }
          subHeadlineDescription
        }
        mainCta {
          text
          url
          appearance
        }
        listOfContentWithImages {
          slug
          heading
          subheading
          title
          description {
            childMarkdownRemark {
              html
            }
            description
          }
          image {
            description
            gatsbyImageData(placeholder: BLURRED, width: 414, quality: 100)
          }
          cta {
            text
            url
            appearance
          }
          subheading
          number
          identifier
          featureFlag
        }
        desktopImages {
          description
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
        mobileImages {
          description
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
        listOfLinks {
          text
          url
        }
        backgroundColor
      }
    }
    product: spreeProduct(slug: { eq: "sti-membership" }) {
      id
      lowest_subscription_price
      icon_image_url
      displayable
      description
      content_token
      master_variant_id
      meta_description
      meta_keywords
      meta_title
      name
      position
      productId
      sku
      slug
      subscription
      shipping_methods {
        amount
        id
        name
      }
      taxons {
        id
        name
        position
        taxonomy_id
        taxonomy_name
      }
      variants {
        id
        is_master
        price
        price_in_dollars
        sku
        total_on_hand
        subscription_plan {
          id
          interval
          interval_count
          name
          percent_discount
        }
        membershipProductInfo {
          markerList {
            markers {
              title
            }
          }
          symptomList {
            symptoms {
              title
            }
          }
          collectionMethods {
            ... on ContentfulCollectionMethod {
              id
              icon: v2Icon {
                file {
                  url
                }
              }
              name: v2Name
              description
            }
          }
          shortDescription
        }
      }
    }
    allSpreeProduct {
      nodes {
        slug
        productId
        contentfulProductInfo {
          productCardImage {
            gatsbyImageData(placeholder: BLURRED, quality: 75)
          }
          boxImage {
            gatsbyImageData(placeholder: BLURRED)
          }
          displayName
          slug
        }
        master_variant_id
        variants {
          price
          is_master
          id
        }
      }
    }
  }
`;
